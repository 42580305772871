import React from "react";
import { NextPageContext } from "next";
import Header from "components/core/header";
import HeaderBanner from "components/core/header-banner";
import Layout from "components/core/layout";
import InfoSection from "components/elements/info-section";
import Link from "components/elements/link";
import Svg from "components/elements/svg";
import TextContent from "components/elements/text-content";
import { serverInstance as Rollbar } from "helpers/rollbar";

const ErrorPage = ({
  statusCode,
  message,
}: {
  statusCode: number;
  message?: string;
}) => {
  let title = "An error occurred";
  let errorMessage = "An error occurred.";
  if (statusCode === 404) {
    title = "404 - Not Found";
    errorMessage =
      "We're sorry, but the page you were looking for doesn't exist.";
  } else if (statusCode === 500) {
    title = "500 - Internal Server Error";
    errorMessage = "Internal server error occurred.";
  } else if (message) {
    if (statusCode === 400) {
      title = "Access denied";
    }
    errorMessage = message;
  }

  return (
    <Layout className="error" title={title}>
      <Header>
        <HeaderBanner
          title={`Error ${statusCode}`}
          icon="home"
          background="/static/images/header-balcony.jpg"
        />
      </Header>

      <InfoSection
        className={`error-page error-${statusCode}`}
        icon="close-icon"
        title={title}
        theme="red"
      >
        <TextContent>
          <p>{errorMessage}</p>
          <Link to="/" className="btn btn-secondary with-arrow--reversed">
            <Svg className="arrow" src="arrow-left" />
            <span>Go back to home page</span>
          </Link>
        </TextContent>
      </InfoSection>
    </Layout>
  );
};

ErrorPage.getInitialProps = ({ res, req, err }: NextPageContext) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;

  // Only require Rollbar and report error if we're on the server
  if (
    !process.browser &&
    process.env.ROLLBAR_SERVER_TOKEN &&
    statusCode !== 404
  ) {
    console.log("Reporting error to Rollbar...");
    Rollbar.error(err as Error, (rollbarError, data) => {
      if (rollbarError) {
        console.log("Error while reporting error to Rollbar:", err);
      } else {
        console.log(
          "Error successfully reported to Rollbar. UUID:",
          data.result.uuid,
        );
      }
    });
  }

  if (res) {
    return { statusCode };
  }

  if (err) {
    return { statusCode: 400, message: err.message };
  }

  return { statusCode: 500 };
};

export default ErrorPage;
