import React, { useEffect, useState } from "react";
import Modal from "components/elements/modal";
import Svg from "../elements/svg";

const ConnectivityNotification = () => {
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    const handleOffline = () => {
      setOpened(true);
    };

    const handlePusherStateChange = (states: any) => {
      if (states.current === "unavailable") {
        setOpened(true);
      }
    };

    window.addEventListener("offline", handleOffline);
    window.pusher.connection.bind("state_change", handlePusherStateChange);

    return () => {
      window.removeEventListener("offline", handleOffline);
      window.pusher.connection.unbind("state_change", handlePusherStateChange);
    };
  }, []);

  return (
    <Modal
      key="connectivityModal"
      isOpen={opened}
      title="Connection lost"
      icon="internet"
      dismissible={false}
    >
      <p>
        We have detected a problem with your internet connection. Please check
        you are connected to WIFI or 3/4G and reload this page via the ‘Refresh’
        button. If the problem persists, this modal will be displayed again.
      </p>
      <div className="text-center" style={{ marginTop: "2rem" }}>
        <button
          type="button"
          className="btn btn-secondary with-arrow"
          onClick={() => window.location.reload()}
        >
          Refresh
          <Svg src="spin" className="arrow" />
        </button>
      </div>
    </Modal>
  );
};

export default ConnectivityNotification;
